<template>
    <div>
    </div>
</template>

<script>
    import {mapGetters} from 'vuex';
    import {dialogs} from '@/constants/text-constants';

    export default {
        name: "BakerCheck",
        created() {
            this.$store.dispatch('loadCakeshopsByBaker', this.uuid).then(() => {
                let cakeshop = this.cakeshops[0];
                if (cakeshop == null) {
                    this.$router.push({ name: 'cakeshops-new'});
                    this.$confirm(dialogs.cakeshop.newBaker.text, {
                        confirmButtonText: dialogs.cakeshop.newBaker.confirm,
                        showCancelButton: false,
                        showClose: false,
                        closeOnClickModal: false,
                        dangerouslyUseHTMLString: true,
                    }).then(() => {}).catch(() => {});
                } else {
                    this.$router.push({ name: 'cakeshop-profile', params: { uuid: cakeshop.uuid, cakeshop: cakeshop } })
                }
            });
        },
        computed: {
            ...mapGetters([
                'uuid',
                'cakeshops'
            ]),
        }
    }
</script>
